import {Component} from '@angular/core';
import {
  CtControlConfiguration,
  CtControlTypes, CtControlValidator, CTFieldInfos,
  CtFormContainerHelper,
  CtRangeControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import { CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { JwtService } from 'projects/ct-manager/src/app/_core/lib/jwt.service';

const UserWorkingHoursMinuteControl: CTFieldInfos = CTFieldInfos.create({
  Field: "Minute",
  Type: "InputText",
  IsNullable: false,
  ControlType: "InputText"
} as CTFieldInfos);


@Component({
  selector: 'app-user-working-hours-minute-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursMinuteControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursMinuteControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setType(CtControlTypes.COUNTER)
    .setOptions(
      CtRangeControlOptions
        .create()
        .setStep(15)
        .setMax(45)
    )
    .setValidators([
      CtControlValidator.create({name: 'required'} as CtControlValidator),
      CtControlValidator.create({name: "min", value: 0} as CtControlValidator),
      CtControlValidator.create({name: "max", value: 45} as CtControlValidator)
    ])
    .setReadonly(true);

  constructor(private jwt : JwtService, private _webapi : CtWebapiService) {
  }


  ngOnInit() {

    this.setup();

  }


  async setup(hours: number | null = null) {

    const userWorkingHours = hours ?? await this.jwt.getDailyWorkingHours() ?? null;

    if (userWorkingHours !== null) {
      // Estrai la parte decimale
      const decimalPart = userWorkingHours - Math.floor(userWorkingHours);

      // Converte la parte decimale nei corrispondenti minuti
      const minutes = Math.round(decimalPart * 100); // Es. 0.25 -> 25, 0.8 -> 80

      // Mappatura dei minuti
      let convertedMinutes: number;

      if(minutes <= 15){
        convertedMinutes = 0;
      } else if (minutes <= 35) {
        convertedMinutes = 15;
      } else if (minutes <= 65) {
        convertedMinutes = 30;
      } else if (minutes <= 95) {
        convertedMinutes = 45;
      } else {
        convertedMinutes = 0;
      }

      // Imposta solo i minuti come valore
      this.control.setValue(convertedMinutes);
    } else {
      this.control.setValue(0);
    }

  }

  setupByUserInfo(userInfoOid: number) {

    const request: DataRequest = new DataRequest()
      .setController(['user', 'GetUserWorkingHours'])
      .setAction(userInfoOid.toString())
      .setQueryParams({hideSpinner: true});

    this._webapi
      .get(request)
      .subscribe({
        next: (result: number | null) => {

          if (result) {

            this.setup(result)

          }

        }
      });

  }
}
