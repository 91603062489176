<form [formGroup]="form" [fxLayout]="'row wrap'">

  <div
    class="control-cntr"
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100"
    [hidden]="!viewModel.isAdministrator">

    <app-user-working-hours-user-info-control></app-user-working-hours-user-info-control>

  </div>

  <div
    class="control-cntr"
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100">

    <app-user-working-hours-work-order-control></app-user-working-hours-work-order-control>

  </div>

  <div
    class="control-cntr"
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100">

    <app-user-working-hours-activity-type-control></app-user-working-hours-activity-type-control>

  </div>

  <div
    class="control-cntr m-b-15"
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100">

    <app-user-working-hours-hours-control></app-user-working-hours-hours-control>
    <app-user-working-hours-minute-control></app-user-working-hours-minute-control>

      <div style="color:#f44336; font-size: 12px; margin-left: 12px; font-weight: 400;" *ngIf="form.hasError('hoursAndMinutesZero')" >
        {{'hoursAndMinutesZero' | translate}}
      </div>

  </div>

  <div
    class="control-cntr m-b-15"
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100">

    <mat-checkbox [formControl]="driverControl"> {{ "iamthedriver" | translate }}</mat-checkbox>

  </div>

  <div
    [hidden]="!driverControl.value"
    class="control-cntr m-b-15"
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100">

    <app-user-working-hours-dictionary-info-control></app-user-working-hours-dictionary-info-control>

  </div>

  <mat-card-actions
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100">

    <ct-button [configuration]="submitButton"></ct-button>

  </mat-card-actions>

</form>
